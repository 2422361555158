<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Posts</h4>
          <div class="page-title-right">
            <b-button
              variant="success"
              v-b-tooltip.hover
              title="Adicionar Post"
              @click.prevent="$router.push({ name: 'blog-post' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12">
        <div class="card">
          <div
            class="card-header bg-white border-bottom d-flex align-items-center justify-content-between"
          >
            <h5 class="card-title">
              <i class="fas fa-filter text-dark mr-2"></i>
              Filtro
            </h5>
            <b-button
              variant="light"
              v-b-tooltip.hover
              title="Limpar Filtro"
              @click.prevent="limparFiltro()"
            >
              <i class="bx bx-sync font-size-16 align-middle"></i>
            </b-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Nome</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Título do post"
                    for="text"
                    v-model="filtro.title"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Status</label>
                <multiselect
                  v-model="status"
                  :options="options_status"
                  placeholder="Status"
                  class="helo"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  label="text"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2 mt-lg-0">
                <label>Categoria</label>
                <multiselect
                  v-model="categorys_parent"
                  placeholder="Categoria Principal"
                  :options="options_categorys"
                  class="helo"
                  label="name"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoading"
                  @search-change="searchCategory"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="posts.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left" v-else>
      <div
        class="col-sm-12 col-md-6 col-lg-4 col-xl-3"
        v-for="(post, index) in posts"
        :key="index"
      >
        <div class="card p-1 border shadow-none">
          <div class="p-3">
            <h5>
              <p class="text-dark">
                {{ post.title }}
              </p>
            </h5>
            <h6>
              <p class="text-dark">
                {{ post.sub_title }}
              </p>
            </h6>
            <p class="text-muted mb-0">
              {{ post.created_at | moment('DD/MM/YYYY') }}
            </p>
          </div>
          <div
            class="position-relative"
            v-if="post.image != null && post.image.length > 0"
          >
            <span v-for="img in post.image" :key="img.id">
              <img
                :src="img.image300"
                v-if="img.capa == 1"
                :alt="post.title"
                class="img-thumbnail img-fluid"
              />
            </span>
          </div>
          <div class="position-relative" v-else>
            <img
              src="@/assets/images/notpro.jpg"
              alt=""
              class="img-thumbnail img-fluid"
            />
          </div>

          <div class="p-3">
            <ul class="list-inline">
              <li class="list-inline-item mr-3">
                <span class="text-muted">
                  <i
                    class="bx bx-purchase-tag-alt align-middle text-muted mr-1"
                  ></i>
                  {{ post.category_blog.name }}
                </span>
              </li>
              <li class="list-inline-item">
                <p
                  class="badge badge-pill badge-soft-success font-size-12"
                  :class="{
                    'badge-soft-success': `${post.status}` == 0,
                    'badge-soft-danger': `${post.status}` == 1,
                  }"
                >
                  {{ post.status == 0 ? 'Ativa' : 'Bloqueada' }}
                </p>
              </li>
            </ul>
            <hr />
            <div class="text-center">
              <b-button
                variant="info"
                class="ml-2"
                v-b-tooltip.hover
                title="Editar Post"
                 @click.prevent="
                      $router.push({
                        name: 'blog-post-edit',
                        params: { id: post.id },
                      })
                    "
              >
                <i class="fas fa-edit"></i>
              </b-button>
              <b-button
                variant="danger"
                class="ml-2"
                v-b-tooltip.hover
                title="Remover Post"
                @click.prevent="removerItem(index, post)"
              >
                <i class="fas fa-trash"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-5" v-if="loadingbtn && posts.length > 0">
      <div class="col-12 text-center">
        <b-button variant="info" @click.prevent="carregarMais()">
          <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
          Carregar Mais
        </b-button>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    Multiselect,
    InfiniteLoading,
  },
  watch: {
    'filtro.title': function () {
      this.listPost()
    },
    status: function () {
      if (this.status != null) {
        this.filtro.status = this.status.value
        this.listPost()
      }
    },
    categorys_parent: function () {
      if (this.categorys_parent != null) {
        this.filtro.category_blog_id = this.categorys_parent.id
        this.listPost()
      }
    },
  },
  data() {
    return {
      name: null,
      status: null,
      categorys_parent: null,
      options_status: [
        { value: 0, text: 'Ativo' },
        { value: 1, text: 'Bloqueado' },
      ],
      options_categorys: [],
      filtroCategory: {
        status: 0,
        limit: 10,
      },
      filtro: {
        page: 1,
        limit: 10,
      },
      isLoading: false,
      loading: true,
      error: false,
      loadingbtn: true,
      posts: [],
    }
  },
  created() {
    this.listCategorySelect()
  },
  methods: {
    ...mapActions('blog', [
      'ActionCategoryBlogAll',
      'ActionPostBlogAll',
      'ActionPostBlogUpdate',
    ]),
    async carregarMais() {
      try {
        this.loadingbtn = true
        await this.ActionPostBlogAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.posts.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.posts = []
        this.loading = false
        this.error = true
      }
    },
    async infiniteHandler($state) {
      try {
        this.loadingbtn = true
        await this.ActionPostBlogAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.posts.push(...res.data.data.list)
            $state.loaded()
          } else {
            this.loadingbtn = false
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.posts = []
        $state.complete()
      }
    },
    async listPost() {
      try {
        this.filtro.page = 1
        this.loadingbtn = true
        await this.ActionPostBlogAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page = 2
            this.posts = res.data.data.list
          } else {
            this.loadingbtn = false
            this.posts = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.posts = []
      }
    },
    async listCategorySelect() {
      try {
        this.isLoading = true

        await this.ActionCategoryBlogAll(this.filtroCategory).then((res) => {
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtroCategory.search = e
      this.listCategorySelect()
    },
    limparFiltro() {
      this.filtro = {
        limit: 10,
        page: 1,
      }
      this.loadingbtn = true
      this.categorys_parent = null
      this.listPost()
    },
    removerItem(index, data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(index, data)
        }
      })
    },
    async removerItemUp(index, data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionPostBlogUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.posts.splice(this.posts.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
